import React from "react";
import ArticleGridItem from "components/articleGridItem";

const ArticleGrid = ({ articles }) => {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-4 lg:max-w-none">
          {articles.map((article) => {
            return <ArticleGridItem article={article.node} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ArticleGrid;

import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const ArticleGridItem = ({ article }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        {article.heroImage && <Img alt="" fluid={article.heroImage.fluid} />}
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-indigo-600">
            <a href="#" className="hover:underline">
              Blog
            </a>
          </p>
          <Link to={`/blog/${article.slug}`} className="block">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
              {article.title}
            </h3>
            <p
              className="mt-3 text-base leading-6 text-gray-500"
              dangerouslySetInnerHTML={{
                __html: article.description.childMarkdownRemark.html,
              }}
            />
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <a href="#">
              {article.author.image && (
                <Img alt="" fluid={article.author.image.fluid} />
              )}
            </a>
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 font-medium text-gray-900">
              <a href="#" className="hover:underline">
                {article.author.name}
              </a>
            </p>
            <div className="flex text-sm leading-5 text-gray-500">
              <time dateTime={article.publishDate}>{article.publishDate}</time>
              <span className="mx-1">&middot;</span>
              <span>6 min read</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArticleGridItem;
